import React from 'react';
import { PureComponent } from 'react';
import './rechart.scss';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

export const Rechart = ({report}) => {
    const data = [
    {
        name: 'Mon',
        Total: 4000
    },
    {
        name: 'Tue',
        Total: 3000
    },
    {
        name: 'Wed',
        Total: 2000
    },
    {
        name: 'Thu',
        Total: 2780
    },
    {
        name: 'Fri',
        Total: 1890
    },
    {
        name: 'Sat',
        Total: 2390
    },
    {
        name: 'Sun',
        Total: 3497
    },
    ];

    const kwachaFormatter = (value) => {
        return `K${value.toLocaleString()}`;
    };

  return (
    <div className='rechart'>
        <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={500}
          height={400}
          data={report}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <XAxis dataKey="name" tick={{ fontSize: 12 }} tickLine={false} axisLine={{ stroke: 'lightgray' }}/>
          <YAxis tick={{ fontSize: 12 }} tickLine={false} axisLine={{ stroke: 'lightgray' }} tickFormatter={kwachaFormatter} />
          <Tooltip formatter={(value) => kwachaFormatter(value)} />
          <Area type="monotone" dataKey="Total" stackId="1" stroke="#6495ED" fill="#6495ED" />
          <Area type="monotone" dataKey="pv" stackId="1" stroke="#29AB87" fill="#29AB87" />
          <Area type="monotone" dataKey="amt" stackId="1" stroke="#FCAE1E" fill="#FCAE1E" />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}
