import React, { useEffect, useState } from 'react';
import './shopproduct.scss';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import baseurl from '../../ourapi';
import { ClipLoader } from 'react-spinners';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';

export default function Shopproduct() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [Data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const storageKeys = {
    '1': 'allproducts',
    '2': 'gateway',
    '3': 'citymall',
    '4': 'website',
    '5': 'hundred',
    '6': 'nophoto',
    '7': 'fiftygate',
    '8': 'fiftycity',
  };

  useEffect(() => {
    const storageKey = storageKeys[id];

    if (storageKey) {
      const storedData = sessionStorage.getItem(storageKey);

      if (storedData) {
        try {
          setData(JSON.parse(storedData));
          setLoading(false);
        } catch (e) {
          console.error("Error parsing stored data", e);
          sessionStorage.removeItem(storageKey); // Clear corrupted data
          fetchProducts();
        }
      } else {
        fetchProducts();
      }
    } else {
      setData(null);
      setLoading(false);
    }
  }, [id]);

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseurl}product/productspower/${id}`);
      setData(response.data);
      if (storageKeys[id]) {
        sessionStorage.setItem(storageKeys[id], JSON.stringify(response.data));
      }
    } catch (error) {
      console.error("Failed to fetch products:", error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Product Name', width: 230 },
    { field: 'price', headerName: 'Price (MWK)', type: 'number', width: 130 },
    {
      field: 'gateway',
      headerName: 'Gateway QTY',
      type: 'number',
      width: 130,
    },
    {
      field: 'citymall',
      headerName: 'Citymall QTY',
      type: 'number',
      width: 130,
    },
    {
      field: 'warehouse',
      headerName: 'Warehouse QTY',
      type: 'number',
      width: 130,
      valueGetter: (params) => {
        const quantity = params.row.quantity || 0;
        const gateway = params.row.gateway || 0;
        const citymall = params.row.citymall || 0;
        return quantity - (gateway + citymall);
      },
    },
    { field: 'quantity', headerName: 'Total', type: 'number', width: 130 },
    { field: 'barcode', headerName: 'Barcode', width: 230 },
    {
      field: 'edit',
      headerName: 'Edit',
      sortable: false,
      width: 230,
      renderCell: (params) => (
        <span className="edit" onClick={() => navigate(`/addproduct/editpage/${params.row._id}`)}>
          <FontAwesomeIcon icon={faPenToSquare} />
        </span>
      ),
    },
  ];

  return (
    <div className="shopd">
      {loading ? (
        <ClipLoader color="#36d7b7" />
      ) : Data ? (
        <>
          <div className="shopupper">
            {Data.owner}
          </div>
          <div className="shoplower">
            <div className="tableshop" style={{ height: 800, width: '100%' }}>
              <DataGrid
                rows={Data.products || []}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 20 },
                  },
                }}
                pageSizeOptions={[20, 40]}
                checkboxSelection
              />
            </div>
          </div>
        </>
      ) : (
        <p>No data available.</p>
      )}
    </div>
  );
}
