import React, { useEffect, useState } from 'react'
import './separate.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGreaterThan, faLessThan } from '@fortawesome/free-solid-svg-icons'
import { DatePicker, DateRangePicker } from 'rsuite'
import 'rsuite/DateRangePicker/styles/index.css';
import axios from 'axios'
import baseurl from '../ourapi'



const getDefaultRange = () => {
  const endDate = new Date(); // Today's date and time
  const startDate = new Date();
  startDate.setDate(endDate.getDate() - 6); // 7 days ago

  // Format dates to ISO 8601 strings
  return {
      startDate: startDate.toISOString(), // Example: "2023-11-04T00:00:00.000Z"
      endDate: endDate.toISOString()       // Example: "2023-11-10T23:59:59.999Z"
  };
};

const defaultRange = getDefaultRange();
console.log(defaultRange);







const Separete = ({ onDataUpdate }) => {
    const [day, setDay] = useState();
    const [dayN, setDayN] = useState();
    const [range, setRange] = useState();
    const [rangeN, setRangeN] = useState();
  
    const handleDateRangeChange = (value) => {
      setRange(value);
    };

    const handleDateChange = (value) => {
        setDay(value);
      };

      useEffect(() => {
        if (day) {
          // Function to convert date to ISO format at midnight UTC
          const convertToMidnightUTC = (date) => {
            // Create a new Date object from the selected date
            const localDate = new Date(date);
            
            // Get the UTC representation of the date at midnight
            const utcDate = new Date(Date.UTC(localDate.getFullYear(), localDate.getMonth(), localDate.getDate()));
            return utcDate.toISOString();
          };
    
          // Convert the selected day to midnight UTC
          const convertedDate = convertToMidnightUTC(day);
          setDayN(convertedDate);
          setRange(null)
        }
      }, [day]);
    
  
      useEffect(() => {
        if (range) {
          // Function to convert date string to ISO format at midnight UTC
          const convertToMidnightUTC2 = (date) => {
            // Create a new Date object from the selected date
            const localDate = new Date(date);
            
            // Get the UTC representation of the date at midnight
            const utcDate = new Date(Date.UTC(localDate.getFullYear(), localDate.getMonth(), localDate.getDate()));
            return utcDate.toISOString();
          };
          const convertToMidnightUTC = (dateString) => {
            const date = new Date(dateString);
            // Set to midnight UTC
            return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())).toISOString();
          };
    
          const dateRange = {
            startDate: convertToMidnightUTC2(range[0]), // Convert start date to midnight UTC
            endDate: convertToMidnightUTC(range[1]) // Convert end date to midnight UTC
          };
    
          // Add one day to the end date to include the whole day
          dateRange.endDate = new Date(new Date(dateRange.endDate).setUTCDate(new Date(dateRange.endDate).getUTCDate() + 1)).toISOString();
    
          setRangeN(dateRange);
          setDay(null)
        }
      }, [range]);



    console.log(dayN)
    console.log(rangeN)



    //processing
    const [bigData, setBigData] = useState()
    const getRange = async (range) => {
        try{
            console.log(rangeN)
            const res = await axios.post(baseurl+"ordersubmitted/report/range", range)
            setBigData(res.data)

        }catch(err){

        }
    }
    const getDay = async () => {
        try{
            const data = {
                day:dayN
            }
            const res = await axios.post(baseurl+"ordersubmitted/report/day", data)
            setBigData(res.data)

          

        }catch(err){

        }
    }
    //reun or fetch
    const getReport = () => {
      if (!range && !day) {
          // Both are null, don't run anything
          return;
      } else if (!range) {
          // Only rangeN is null, run getDay
          getDay();
      } else if (!day) {
          // Only dayN is null, run getRange
          getRange(rangeN);
      }
      // If both are not null, don't run anything
  };

  //sumtotals
  const [totalSum, setTotalSum] = useState(0);

  useEffect(() => {
      // Calculate the sum of all Total values in bigData
      if(bigData){

        if(Array.isArray(bigData)){
          const sum = bigData.reduce((acc, item) => acc + item.Total, 0);
          setTotalSum(sum); 
          if (onDataUpdate) {
            onDataUpdate(bigData, sum);
          }
        }else{
          const sum = bigData.Total
          const bigDataa = [bigData]
          setTotalSum(sum)
          if (onDataUpdate) {
            onDataUpdate(bigDataa, sum);
        }
        }
      }
  }, [bigData, onDataUpdate]); 



  //onload get last 7 days
  useEffect(()=>{
    getRange(defaultRange)
  }, [defaultRange])

  const formatNumberWithCommas = (value) => {
    return value.toLocaleString('en-US');
  };

  
  
  return (
    <div className="separateMain">
        
            <div className='rangeHome'>
            <div className="left">
                <span className="title">Sales Report</span>
                <span className="heading">Detailed</span>
            </div>
            <div className="right">
                <div className="leftSide">
                    <span className="day">
                    <div className="datearea">
                        <DatePicker placeholder="Day" onChange={handleDateChange}/>
                    </div>
                    </span>
                    <span className="week">
                    <div className="datearea">
                        <DateRangePicker placeholder="Range"  onChange={handleDateRangeChange}/>
                    </div>
                    </span>
                </div>
                <div className="rightSide">
                    <span className="back"><FontAwesomeIcon icon={faLessThan} /></span>
                    <span className="visible">12 August, 2023</span>
                    <span className="back"><FontAwesomeIcon icon={faGreaterThan} /></span>
                    <button onClick={()=>getReport()}>Go</button>
                </div>
            </div>
            </div>





            
            <div className="dataArea">
                <div className="right">
                      <div className="leftt">
                          <img src="images/ecommerce.png" alt="" />
                      </div>
                      <div className="rightt">
                          <div className="heading">Online Sales</div>
                          <div className="totalammounts">
                              <span className="amtHeader">Total Revenue</span>
                              <span className="amtamounT"><span>MWK</span> {totalSum && formatNumberWithCommas(totalSum)}</span>
                          </div>
                      </div>
                  </div>
                <div className="right">
                    <div className="leftt">
                        <img src="images/shop.png" alt="" />
                    </div>
                    <div className="rightt">
                        <div className="heading">Shop Sales</div>
                        <div className="totalammounts">
                            <span className="amtHeader">Total Revenue</span>
                            <span className="amtamounT"><span>MWK</span> 450,000</span>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default Separete
