import React, { useState } from 'react'
import './home.scss'
import Navbar from '../../mypackets/navbar/Navbar'
import { Range } from '../../mypackets/range/Range'
import { Summary } from '../../mypackets/summary/Summary'
import { Graph } from '../../mypackets/graphs/Graph'
import Separete from '../../components/separete'

export const Home = () => {
  const [bigData, setBigData] = useState([]);
    const [totalSum, setTotalSum] = useState(0.00);

    // Callback function to receive data from the child component
    const handleDataFromChild = (data, sum) => {
        setBigData(data);
        setTotalSum(sum);
    };
    // console.log(bigData)
  return (
    <div className='home'>
        <Navbar/>
        <Range/>
        <Summary/>
        <Separete onDataUpdate={handleDataFromChild}/>
        <Graph data={bigData}/>
    </div>
  )
}
