import React from 'react'
import { DateRangePicker } from 'rsuite'
// improt DateRangePicker from 'rsuite/DateRangePicker';

// (Optional) Import component styles. If you are using Less, import the `index.less` file. 
import 'rsuite/DateRangePicker/styles/index.css';

const DatePickerG = () => {
  return (
    <div>
      <DateRangePicker/>
    </div>
  )
}

export default DatePickerG
