import React from 'react'
import './graph.scss'
import { Rechart } from '../../components/Rechart'
import { Trending } from '../trendingproduct/Trending'

export const Graph = ({data}) => {
  return (
    <div className='Graph'>
        <div className="left">
            <div className="upper"><span>Summary</span><span>View more</span></div>
            <div className="lower">
                <div className="selection">
                    <div className="orders">
                        <span className="figure">Shop</span>
                        <span className="title">Revenue</span>
                    </div>
                    <div className="orders active">
                        <span className="figure">Online</span>
                        <span className="title">Revenue</span>
                    </div>
                </div>
                <div className="graphyy">
                    <Rechart report={data}/>
                </div>
            </div>
        </div>
        <div className="right">
            <div className="upper"><span>Top Selling Products</span><span>View more</span></div>
            <div className="lower">
                <Trending/>
                <Trending/>
                <Trending/>
            </div>
        </div>
    </div>
  )
}
